import { handleQueryResolve } from '../utils'

export default function (rxNum) {
  const attributes = []

  const queryStr = `
  SELECT
  RxNum,
  CONVERT(DATE,Rx.FillDate,112) AS FillDate
FROM
  pharmacy.dbo.Rx
WHERE
  MixID >= 1 AND
   RxNum = ${rxNum}
      `

  // console.log(queryStr)

  return this.query(queryStr, attributes).then(handleQueryResolve)
}
